import React, { useEffect, useState } from "react";


// reactstrap components
import {
  FormGroup,
  Form,

  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { get, post } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";

const Edit = () => {
  const { register, handleSubmit, formState: { errors }, unregister, watch, setValue } = useForm();
  const [features, setFeatures] = useState([{ id: 0 }])
  const [featured, setFeatured] = useState([])
  const location = useLocation()
  const Navigate = useNavigate();
  const { upfrontFee } = watch()


  useEffect(() => {
    getAllFeatures()
    if (location?.search) {
      getOnePlans(location?.search?.split("?")[1])
    }
  }, [])

  const getAllFeatures = () => {
    get(`/blog/plan-feature`).then((res) => {
      setFeatured(res?.datas)
    }).catch((error) => {
      console.log(error, "erre");
    })
  }

  const getOnePlans = (id) => {
    get(`/pricing/${id}`).then((res) => {
      if (res?.datas) {
        setValue("planName", res?.datas?.planName)
        setValue("isActive", res?.datas?.isActive)
        setValue("upfrontFee", res?.datas?.upfrontFee)
        setValue("description", res?.datas?.description)
        // setValue("monthlyFee", res?.datas?.monthlyFee)
        setValue("period", res?.datas?.period)
        // setValue("parSalesPercentage", res?.datas?.parSalesPercentage)

        // setValue("salesPercentage", res?.datas?.salesPercentage)

        if (res?.datas?.upfrontFee == "upfrontFee") {
          setValue("upfrontFeeAmount", res?.datas?.amount)

        } if (res?.datas?.upfrontFee == "monthlyFee") {
          setValue("monthlyFeeAmount", res?.datas?.amount)

        }
        if (res?.datas?.upfrontFee == "parSalesPercentage") {
          setValue("parSalesPercentageAmount", res?.datas?.amount)

        }
        if (res?.datas?.upfrontFee == "salesPercentage") {
          setValue("salesPercentageAmount", res?.datas?.amount)

        }

        if (res?.datas?.featuresIds?.length > 0) {
          res?.datas?.featuresIds?.map((item, index) => {
            setValue(`featuresIds[${index}].featureId`, item)
          })

        }




      }

    }).catch((error) => {
      console.log(error, "erre");
    })
  }


  const onSubmit = (data) => {


    let featuresIds = []
    if (data?.featuresIds?.length > 0) {
      data?.featuresIds?.map((item) => {

        if (item.featureId) {

          featuresIds.push(item?.featureId)
        }
      })
    }

    // data.featuresIds = featuresIds

    const sendData = {
      featuresIds: featuresIds,
      description: data?.description,
      period: data?.period,
      planName: data?.planName,
      upfrontFee: data?.upfrontFee,
      isActive: data?.isActive,
      amount: data[`${data?.upfrontFee}Amount`],
      id: location?.search?.split("?")[1]

    }
    data.id = location?.search?.split("?")[1]

    const Url = `${baseURL}/pricing/edit`
    post(Url, sendData).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
        Navigate("/admin/pricing")
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }


  const addFeatures = () => {
    let newFeatures = [...features]
    let ids = { id: features[newFeatures.length - 1].id + 1 }
    newFeatures.push(ids)
    setFeatures(newFeatures)

  }


  const deleteHandler = (id) => {
    unregister(`features[${id}]`);
    let newData = features?.filter((item) => item.id !== id)
    setFeatures(newData)
  }


  return (
    <>
      <Row className="mt-7 mx-0">
        <Col className="mb-5 mb-xl-0 ml-5" xl="11">
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Plan Edit</h2>
                <Button
                  className='bi-trash justify-content-right'
                  style={{ position: "absolute", right: 0, marginRight: "40px" }}
                  onClick={() => Navigate(`/admin/pricing`)}
                  color="primary"
                >
                  Back
                </Button>
              </div>
              <Form style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Plan Name <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Plan Name"
                          type="text"
                          autoComplete="new-email"
                          {...register("planName", { required: true })}
                          className="border form-control"
                        />
                      </InputGroup>
                      {errors.planName && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  {/* 
            <Col md="4">
            <FormGroup className="mb-3">
               <label className="d-flex">Pricing <span className="text-danger">*</span></label>
               <InputGroup className="input-group-alternative">
                  <input
                  placeholder="Pricing"
                  type="number"
                  autoComplete="new-email"
                  {...register("pricing", { required: true })}
                  className="border form-control"
                  />
               </InputGroup>
               {errors.pricing && <span className="text-danger d-flex"> This field is required</span>}
            </FormGroup>
            </Col> */}
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Period<span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <select
                          id="role"
                          name="role"
                          className="block appearance-none w-full bg-white border form-control border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          {...register("period", { required: "This field is required" })}
                        >
                          <option value="" disabled selected>Select your period</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Yearly">Yearly</option>
                        </select>
                      </InputGroup>
                      {errors.period && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Plan Status <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <select
                          id="role"
                          name="role"
                          className="block appearance-none w-full bg-white border form-control border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          {...register("isActive", { required: "This field is required" })}
                        >
                          <option value="" disabled selected>Select your plan status</option>
                          <option value={true}>Active</option>
                          <option value={false}>Deactive</option>
                        </select>
                      </InputGroup>
                      {errors.isActive && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Description <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <textarea
                          id="description"
                          name="description"
                          placeholder="Enter description"
                          {...register("description", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                      </InputGroup>
                      {errors.description && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col md="10">
                    <Row>
                      <Col md="3" className="text-left">
                        <label className='d-block mb-3'>Upfront Fee</label>
                        <label class="switch mb-0">
                          <input type="checkbox"  {...register("upfrontFee", { required: false })} />
                          <span class="slider round"></span>
                        </label>
                      </Col>
                      <Col md="6">
                        {upfrontFee &&
                          <FormGroup className="mb-3">
                            <label className="d-flex">Amount </label>
                            <InputGroup className="input-group-alternative">
                              <input
                                placeholder="Amount"
                                type="text"
                                autoComplete="new-email"
                                {...register("upfrontFeeAmount", { required: upfrontFee })}
                                className="border form-control"
                              // className={upfrontFee ? "d-block border form-control":"d-none border form-control"}
                              />
                            </InputGroup>
                            {errors.upfrontFeeAmount && <span className="text-danger d-flex"> This field is required</span>}
                          </FormGroup>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3" className="text-left">
                        <label className='d-block mb-3'>Monthly Fee</label>
                        <label class="switch">
                          <input type="checkbox"  {...register("monthlyFee", { required: false })} />
                          <span class="slider round"></span>
                        </label>
                      </Col>{monthlyFee &&
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <label className="d-flex">Amount </label>
                            <InputGroup className="input-group-alternative">
                              <input
                                placeholder="Amount"
                                type="text"
                                autoComplete="new-email"
                                {...register("monthlyFeeAmount", { required: monthlyFee })}
                                className="border form-control"
                              />
                            </InputGroup>
                            {errors.monthlyFeeAmount && <span className="text-danger d-flex"> This field is required</span>}
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col md="3" className="text-left">
                        <label className='d-block mb-3'>Sales Percent</label>
                        <label class="switch">
                          <input type="checkbox"  {...register("salesPercentage", {
                            required: false,

                          })} />
                          <span class="slider round"></span>
                        </label>
                      </Col>
                      {salesPercentage &&
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <label className="d-flex">Percent </label>
                            <InputGroup className="input-group-alternative">
                              <input
                                placeholder="Percent"
                                type="text"
                                autoComplete="new-email"
                                {...register("salesPercentageAmount", {
                                  required: salesPercentage,
                                  max: {
                                    value: 100,
                                    message: "Sales Percent cannot exceed 100"
                                  }
                                })}
                                className="border form-control"
                              />
                            </InputGroup>
                            {errors.salesPercentageAmount && <span className="text-danger d-flex">{errors?.salesPercentageAmount?.message ? errors?.salesPercentageAmount?.message : "This field is required "}</span>}
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col md="3" className="text-left">
                        <lebel className='d-block mb-3'>Per Sales Fee</lebel>
                        <label class="switch">
                          <input type="checkbox"  {...register("parSalesPercentage", { required: false })} />
                          <span class="slider round"></span>
                        </label>
                      </Col>
                      {parSalesPercentage &&
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <label className="d-flex">Amount </label>
                            <InputGroup className="input-group-alternative">
                              <input
                                placeholder="Amount"
                                type="text"
                                autoComplete="new-email"
                                {...register("parSalesPercentageAmount", { required: parSalesPercentage })}
                                className="border form-control"
                              />
                            </InputGroup>
                            {errors.parSalesPercentageAmount && <span className="text-danger d-flex"> This field is required</span>}
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                  </Col>
                </Row> */}
                <Row>
                  <Col md="10">
                    <Row>
                      <Col md="3" className="text-left">
                        {/* <label className='d-block mb-3'>Upfront Fee</label> */}
                        {/* <label class="switch mb-0">
                                       <input type="checkbox"  {...register("upfrontFee", { required: false })} />
                                       <span class="slider round"></span>
                                    </label> */}
                        <label>
                          <input
                            type="radio"
                            value={"upfrontFee"}
                            className="switch"
                            defaultChecked
                            {...register("upfrontFee", { required: false })}
                          /> Upfront Fee
                        </label>
                      </Col>
                      <Col md="6">
                        {upfrontFee == "upfrontFee" &&
                          <FormGroup className="mb-3">
                            <label className="d-flex">Amount </label>
                            <InputGroup className="input-group-alternative">
                              <input
                                placeholder="Amount"
                                type="text"
                                autoComplete="new-email"
                                {...register("upfrontFeeAmount", { required: upfrontFee == "upfrontFee" ? true : false })}
                                className="border form-control"
                              // className={upfrontFee ? "d-block border form-control":"d-none border form-control"}
                              />
                            </InputGroup>
                            {errors.upfrontFeeAmount && <span className="text-danger d-flex"> This field is required</span>}
                          </FormGroup>
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3" className="text-left">
                        {/* <label className='d-block mb-3'>Monthly Fee</label>
                                    <label class="switch">
                                       <input type="checkbox"  {...register("monthlyFee", { required: false })} />
                                       <span class="slider round"></span>
                                    </label> */}
                        <label>
                          <input
                            type="radio"
                            value={"monthlyFee"}
                            className="switch"
                            {...register("upfrontFee", { required: false })}
                          /> Monthly Fee
                        </label>
                      </Col>{upfrontFee == "monthlyFee" &&
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <label className="d-flex">Amount </label>
                            <InputGroup className="input-group-alternative">
                              <input
                                placeholder="Amount"
                                type="text"
                                autoComplete="new-email"
                                {...register("monthlyFeeAmount", { required: upfrontFee == "monthlyFee" ? true : false })}
                                className="border form-control"
                              />
                            </InputGroup>
                            {errors.monthlyFeeAmount && <span className="text-danger d-flex"> This field is required</span>}
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col md="3" className="text-left">
                        {/* <label className='d-block mb-3'>Sales Percent</label> */}
                        {/* <label class="switch">
                                       <input type="checkbox"  {...register("salesPercentage", { required: false })} />
                                       <span class="slider round"></span>
                                    </label> */}
                        <label>
                          <input
                            type="radio"
                            value={"salesPercentage"}
                            className="switch"
                            {...register("upfrontFee", { required: upfrontFee == "salesPercentage" ? true : false })}
                          /> Sales Percent
                        </label>
                      </Col>
                      {upfrontFee == "salesPercentage" &&
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <label className="d-flex">Percent </label>
                            <InputGroup className="input-group-alternative">
                              <input
                                placeholder="Percent"
                                type="text"
                                autoComplete="new-email"
                                {...register("salesPercentageAmount", {
                                  required: upfrontFee == "salesPercentage" ? true : false,
                                  max: {
                                    value: 100,
                                    message: "Sales Percent cannot exceed 100"
                                  }
                                })}

                                className="border form-control"
                              />
                            </InputGroup>
                            {errors.salesPercentageAmount && <span className="text-danger d-flex">{errors?.salesPercentageAmount?.message ? errors?.salesPercentageAmount?.message : "This field is required "}</span>}                                       </FormGroup>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col md="3" className="text-left">
                        {/* <lebel className='d-block mb-3'>Per Sales Fee</lebel> */}
                        {/* <label class="switch">
                                       <input type="checkbox"  {...register("parSalesPercentage", { required: false })} />
                                       <span class="slider round"></span>
                                    </label> */}
                        <label>
                          <input
                            type="radio"
                            value={"parSalesPercentage"}
                            className="switch"
                            {...register("upfrontFee", { required: false })}
                          /> Per Sales Fee
                        </label>
                      </Col>
                      {upfrontFee == "parSalesPercentage" &&
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <label className="d-flex">Amount </label>
                            <InputGroup className="input-group-alternative">
                              <input
                                placeholder="Amount"
                                type="text"
                                autoComplete="new-email"
                                {...register("parSalesPercentageAmount", { required: upfrontFee == "parSalesPercentage" ? true : false })}
                                className="border form-control"
                              />
                            </InputGroup>
                            {errors.parSalesPercentageAmount && <span className="text-danger d-flex"> This field is required</span>}
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="12" className="text-left">
                    <h2>Features</h2>
                  </Col>
                  {
                    featured?.length > 0 &&
                    featured.map((item, index) => {
                      return (
                        <>
                          <Col md="3">
                            <div className="custom-control custom-control-alternative custom-checkbox mb-3 text-left">
                              <input class="custom-control-input" id={item?._id} type="checkbox" value={item?._id}  {...register(`featuresIds[${index}].featureId`, { required: false })} />
                              <label class="custom-control-label" for={item?._id}>{item?.name}</label>
                            </div>
                            {/* <label class="switch">
            <input type="checkbox" value={item?._id}  {...register(`featuresIds[${index}].featureId`, { required: false })} />
            <span class="slider round"></span>
            </label> */}
                          </Col>
                        </>
                      )
                    })
                  }
                </Row>
                {/* 
         <Row>
            <Col md="4">
            <FormGroup className="mb-3">
               <label className="d-flex">Description <span className="text-danger">*</span></label>
               <InputGroup className="input-group-alternative">
                  <textarea
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  {...register("description", { required: "This field is required" })}
                  className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
               </InputGroup>
               {errors.description && <span className="text-danger d-flex"> This field is required</span>}
            </FormGroup>
            </Col>
         </Row>
         */}
                {/* 
         <Col md="4">
         <FormGroup className="mb-3">
            <label className="d-flex" style={{    justifyContent: "left",
            display: "flex"}}>Features  <span className="text-danger">*</span></label>
            <div  color="primary" onClick={() => addFeatures()} style={{    justifyContent: "right",
               display: "flex",cursor:"pointer"}}> +Add
            </div>
            {
            features.length > 0 && features.map((item,key) => {
            return (
            <>
            <InputGroup className="input-group-alternative">
               <input
               id="description"
               name="description"
               placeholder="Enter description"
               {...register(`features[${item?.id}]`, { required: "This field is required" })}
               className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
               />
               {features?.length > 1  &&
               // 
               <div onClick={() => deleteHandler(item?.id)}>delete</div>
               <i onClick={() => deleteHandler(item.id)} class="fa fa-trash" aria-hidden="true" style={{ color: "red", width: "30px", fontSize: "20px",cursor:"pointer" }}></i>
               }
            </InputGroup>
            {errors?.features?.[item?.id] && <span className="text-danger d-flex"> This field is required</span>}
            </>
            )
            })
            }
         </FormGroup>
         </Col> */}
                {/* 
         <Row>
            <Col md="4">
            <FormGroup className="mb-3">
               <label className="d-flex">Description <span className="text-danger">*</span></label>
               <InputGroup className="input-group-alternative">
                  <textarea
                  id="description"
                  name="description"
                  placeholder="Enter description"
                  {...register("description", { required: "This field is required" })}
                  className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
               </InputGroup>
               {errors.description && <span className="text-danger d-flex"> This field is required</span>}
            </FormGroup>
            </Col>
         </Row>
         */}
                <Button color="primary" type="submit" className="d-flex">
                  Submit
                </Button>
              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row>

      {/* <Row className="mt-7">
        <Col className="mb-5 mb-xl-0 ml-5" xl="11">
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Plan Edit</h2>
                <Button
                  className='bi-trash justify-content-right'
                  style={{ position: "absolute", right: 0, marginRight: "40px" }}
                  onClick={() => Navigate(`/admin/pricing`)}
                  color="primary"
                >
                  Back
                </Button>
              </div>
              <Form style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Plan Name <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Plan Name"
                          type="text"
                          autoComplete="new-email"
                          {...register("planName", { required: true })}
                          className="border form-control"
                          
                        />
                      </InputGroup>
                      {errors.planName && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                 
        
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Plan Status <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <select
                          id="role"
                          name="role"
                          className="block appearance-none w-full bg-white border form-control border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                          {...register("isActive", { required: "This field is required" })}
                      
                      >
                          <option value="" disabled selected>Select your period</option>
                          <option value={true}>Active</option>
                          <option value={false}>Deactive</option>
                       
                        </select>
                      </InputGroup>
                      {errors.isActive && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                
               
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Description <span className="text-danger">*</span></label>
                      <InputGroup className="input-group-alternative">
                        <textarea
                          id="description"
                          name="description"
                          placeholder="Enter description"
                          {...register("description", { required: "This field is required" })}
                          className="mt-1 block w-full px-3 py-2 form-control border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        
                        />
                      </InputGroup>
                      {errors.description && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                <Col md="1">
                  <lebel>Upfront Fee</lebel>
                <label class="switch">
                <input type="checkbox"  {...register("upfrontFee", { required: false })} />
                <span class="slider round"></span>
              </label>
                </Col>
                <Col md="3">
                  {upfrontFee &&
                    <FormGroup className="mb-3">
                      <label className="d-flex">Amount </label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Amount"
                          type="text"
                          autoComplete="new-email"
                          {...register("upfrontFeeAmount", { required: upfrontFee })}
                          className="border form-control"
                          // className={upfrontFee ? "d-block border form-control":"d-none border form-control"}
                          
                        />
                      </InputGroup>
                      {errors.upfrontFeeAmount && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  } 
                  </Col>
                  </Row>
                  <Row>
                  <Col md="1">
                  <lebel>Monthly Fee</lebel>
                <label class="switch">
                <input type="checkbox"  {...register("monthlyFee", { required: false })} />
                <span class="slider round"></span>
              </label>
                </Col>{monthlyFee &&
                <Col md="3">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Amount </label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Amount"
                          type="text"
                          autoComplete="new-email"
                          {...register("monthlyFeeAmount", { required: monthlyFee })}
                          className="border form-control"
                          
                        />
                      </InputGroup>
                      {errors.monthlyFeeAmount && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                   }
                    </Row>
                    <Row>
                  <Col md="1">
                  <lebel>Sales Percentage</lebel>
                <label class="switch">
                <input type="checkbox"  {...register("salesPercentage", { required: false })} />
                <span class="slider round"></span>
              </label>
                </Col>
               {salesPercentage &&
                <Col md="3">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Amount </label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Amount"
                          type="text"
                          autoComplete="new-email"
                          {...register("salesPercentageAmount", { required: salesPercentage })}
                          className="border form-control"
                          
                        />
                      </InputGroup>
                      {errors.salesPercentageAmount && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
  }
                </Row>

                <Row>
                <Col md="1">
                  <lebel>Per Sales Fee</lebel>
                <label class="switch">
                <input type="checkbox"  {...register("parSalesPercentage", { required: false })} />
                <span class="slider round"></span>
              </label>
                </Col>
                {parSalesPercentage && 
                <Col md="3">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Per Sales Amount </label>
                      <InputGroup className="input-group-alternative">
                        <input
                          placeholder="Amount"
                          type="text"
                          autoComplete="new-email"
                          {...register("parSalesPercentageAmount", { required: parSalesPercentage })}
                          className="border form-control"
                          
                        />
                      </InputGroup>
                      {errors.parSalesPercentageAmount && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
              }
                </Row>

                <Row>
                  {
                    featured?.length > 0 && 

                    featured.map((item,index)=>{
                      return(
                        <>
                        <Col md="3">
                        <lebel>{item?.name}</lebel>
                        <label class="switch">
                        <input type="checkbox" value={item?._id}  {...register(`featuresIds[${index}].featureId`, { required: false })} />
                        <span class="slider round"></span>
                      </label>
                      </Col>
                      </>
                      
                      )
                    
                    })
                  }

                </Row>
           
            

         
              
               
         
                <Button color="primary" type="submit" className="d-flex">
                  Submit
                </Button>
              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row> */}
    </>
  );

}

export default Edit;