
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
  } from "reactstrap";
  import { useForm } from "react-hook-form";
  import { baseURL } from '../config/config';
  import { post } from "../utils/apiManager";
  import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

  
  
  const ForgotPassword = () => {
  
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const Navigate = useNavigate()
  
    const onSubmit = (data) => {
  
      
      const Url = `${baseURL}/admin/forgot-password`
      post(Url, data).then((data) => handleLogin(data));
      const handleLogin = (data) => {
        if (data?.status) {
          toast.success(data?.message);       
           Navigate(`/auth/reset-password/${data?.validateSting}`)     
        }
        else {
          toast.error(data?.message)
        }
      };
    }
  
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
           
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Forgot Password</small>
              </div>
              <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    {/* <InputGroupAddon addonType="prepend"> */}
                      <InputGroupText>
                      <i class="fa fa-envelope" aria-hidden="true"></i>

                      </InputGroupText>
                    {/* </InputGroupAddon> */}
                    <input
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                      {...register("email", { required: true })}
                    />
  
                  </InputGroup>
                  {errors.email && <span className="text-danger d-flex"> This field is required</span>}
                </FormGroup>
            
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit"
                   
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
         
        </Col>
      </>
    );
  };
  
  export default ForgotPassword;
  