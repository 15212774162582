import React, { useEffect, useState } from "react";


// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post, get } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";
import CKEditor from 'ckeditor4-react';
import Editor from "../../components/Editor";

const Edit = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const Navigate = useNavigate()
  const location = useLocation()
  const [editorData, setEditorData] = useState()
  const [showImageUrl, setShowImageUrl] = useState("")
  const [showImage, setShowImage] = useState(false)
  const [showImageCheck, setShowImageCheck] = useState(false)
  useEffect(() => {
    if (location?.search) {
      getOneBlog(location?.search?.split("?")[1])
    }
  }, [])


  const getOneBlog = (id) => {
    get(`/blog/${id}`).then((res) => {
      if (res?.datas) {
        setValue("title", res?.datas?.title)

        setValue("image", res?.datas?.image)
        if (res?.datas?.description) {

          setEditorData(res?.datas?.description)
        }
        setShowImageUrl(res?.datas?.image)

        if (res?.datas?.image) {
          setShowImage(true)
        }

      }

    }).catch((error) => {
      console.log(error, "erre");
    })
  }


  const onSubmit = (data) => {

    const Url = `${baseURL}/blog/edit`
    // if(editorData == ""){
    //     toast.error("description field is required") 
    //     return 
    // }


    let formData = new FormData();
    formData.append("title", data?.title)
    if (showImageCheck) {
      formData.append("image", data?.image?.[0])
    }
    formData.append("description", editorData)
    formData.append("isShow", showImageCheck)
    formData.append("id", location?.search?.split("?")[1])

    post(Url, formData, 1).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
        Navigate("/admin/blog")

      }
      else {

        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }

  const imageUrlChange = (e) => {

    const file = e?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Get Base64 part only
        setShowImageUrl(base64String)
      };

      reader.readAsDataURL(file);
    }
  }


  return (
    <>

      <Row className="mt-7" >
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Article Edit</h2>

                <Button className='bi-trash justify-content-right' style={{
                  position
                    : "absolute", right: 0, marginRight: "40px"
                }} onClick={() => Navigate(`/admin/blog`)} color="primary">Back</Button>
              </div>
              <Form className="" style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                <Row>



                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Title <span className="text-danger">*</span></label>

                      <InputGroup className="input-group-alternative">


                        <input
                          placeholder="Title"
                          type="text"
                          autoComplete="new-email"
                          {...register("title", { required: true })}
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.title && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Image <span className="text-danger">*</span> </label>

                      <InputGroup className="input-group-alternative">
                        {/* <InputGroupAddon addonType="prepend"> */}
                        {/* </InputGroupAddon> */}
                        <input
                          placeholder="Please Select Image"
                          type="file"
                          {...register("image", { required: { showImage } })}
                          className="form-control border"
                          onChange={(e) => { setShowImageCheck(true); imageUrlChange(e) }}
                        />

                      </InputGroup>
                      {showImage && <img src={showImageCheck ? `data:image/png;base64,${showImageUrl}` : baseURL + "/" + showImageUrl} width="100px" style={{ display: "flex", marginTop: "10px" }} />}
                      {errors.image && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Editor editorData={editorData} setEditorData={setEditorData} />
                  </Col>
                </Row>

                <Button color="primary" type="submit" className="d-flex" style={{ marginTop: "10px" }} >
                  Update
                </Button>

              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </>
  );

}

export default Edit;