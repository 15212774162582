import React, { useEffect, useState } from "react";
import {
    FormGroup,
    Form,

    InputGroup,
    Row,
    Col,
    Button,
    Card,
    CardHeader
} from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";



const Add = () => {
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const Navigate = useNavigate()


    const onSubmit = (data) => {
        const Url = `${baseURL}/sale-type/create`

        post(Url, data, 1).then((data) => {
            if (data?.status) {
                toast.success(data?.message);
                Navigate("/admin/sale-type")
            }
            else {
                toast.error(data?.message)
            }
        }).catch(err => {
            toast.error(err.response.data.error)

        })
    }


    return (
        <>

            <Row className="mt-7" >
                <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <div className="d-flex">
                                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Sale Type Add</h2>

                                <Button className='bi-trash justify-content-right' style={{
                                    position
                                        : "absolute", right: 0, marginRight: "40px"
                                }} onClick={() => Navigate(`/admin/sale-type`)} color="primary">Back</Button>
                            </div>
                            <Form className="" style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                                <Row>


                                    <Col md="4">
                                        <FormGroup className="mb-3">
                                            <label className="d-flex">Name <span className="text-danger">*</span></label>

                                            <InputGroup className="input-group-alternative">


                                                <input
                                                    placeholder="Name"
                                                    type="text"
                                                    {...register("name", { required: true })}
                                                    className="form-control border"
                                                />

                                            </InputGroup>
                                            {errors.name && <span className="text-danger d-flex"> This field is required</span>}
                                        </FormGroup>
                                    </Col>



                                </Row>

                                <Button color="primary" type="submit" className="d-flex" style={{ marginTop: "10px" }} >
                                    Submit
                                </Button>

                            </Form>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
        </>
    );

}

export default Add;