import React, { useEffect, useState } from "react";
// reactstrap components
import {
    Table,
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    InputGroup,
    FormGroup,
    Form
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useNavigate } from 'react-router-dom';
import { get, post } from "../../utils/apiManager";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
// import PaginatedItems from "../../components/ReactPagination";
import Pagination from 'react-js-pagination'
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";


const Index = () => {
    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
    const { register: registerContactUs, handleSubmit: handleSubmitContactUs, formState: { errors: ErrorsContactUs }, reset: contectReset, setValue: setContactValue } = useForm();


    const [contactUs, setContactUS] = useState([])
    const [subjectValue, setSubjectValue] = useState("")
    const [email, setEmail] = useState("")
    const Navigate = useNavigate()
    const [showModel, setShowModel] = useState(false)
    const [count, setCount] = useState()
    const [checkedValue, setCheckedValue] = useState(false)
    const [pageData, setPageData] = useState({
        page: 1,
        perPage: 25,
        searchItem: "",
    });
    const [checkBoxData, setCheckBoxData] = useState([])

    useEffect(() => {
        getAllNewsletter()
    }, [pageData])

    const getAllNewsletter = () => {
        get(`/news-letter/contact-us?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
            }&searchItem=${getValues("companyName")}`).then((res) => {
                setContactUS(res?.data)
                setCount(res?.pages)
            }).catch((error) => {
                console.log(error, "erre");
            })
    }

    const singleOneResetEmail = () => {
        if (checkBoxData?.length == 1) {
            setCheckBoxData([])
        }
    }


    const onSubmit = (data) => {
        getAllNewsletter()
    }

    const clearSearch = () => {
        setValue("companyName", "")
        getAllNewsletter()
    }

    const changePage = (index) => {
        setPageData({
            ...pageData,
            page: index,
        });
    };


    const onSubmitContactUS = (data) => {
        if (checkBoxData.length > 0) {

            const Url = `${baseURL}/news-letter/contact-us-reply`

            post(Url, { email: checkBoxData, message: data.message, subject: data?.subject }).then((data) => {
                if (data?.status) {
                    toast.success(data?.message);
                    setShowModel(false)
                    setEmail("")
                    contectReset()
                    setCheckBoxData([])
                }
                else {

                    toast.error(data?.message)
                }
            }).catch(err => {
                toast.error(err.response.data.error)

            })
        }
    }


    const checkBoxHandler = (email) => {
        let findData = checkBoxData?.find(item => item == email)
        if (findData) {
            let filterData = checkBoxData?.filter((item) => item != email)
            setCheckBoxData(filterData)
        } else {
            setCheckBoxData((prev) => [...prev, email])
        }
    }

    const allCheck = (val) => {
        if (val) {
            let newData = []
            contactUs?.map((item) => {
                newData.push(item.email)
            })
            setCheckBoxData(newData)

        } else {
            setCheckBoxData([])
        }
        setCheckedValue(!checkedValue)

    }

    useEffect(() => {
        if (contactUs.length > 0) {
            if (checkBoxData?.length == 1) {
                setContactValue("subject", subjectValue)
            } else {
                setContactValue("subject", "")
            }
            if (contactUs.length == checkBoxData.length) {
                setCheckedValue(true)
            } else {
                setCheckedValue(false)

            }
        }

    }, [checkBoxData])



    return (
        <>
            <Row className="mt-7" >
                <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <Row className="align-items-center " >
                                <div className="col">
                                    <h3 className="mb-0" style={{ textAlign: "left" }}>Contact Us Management</h3>
                                </div>
                                <div className="col-9 text-right">

                                    {
                                        checkBoxData?.length > 0 &&
                                        <>
                                            <Button
                                                color="primary"
                                                onClick={() => setShowModel(true)}
                                                className="mr-4"
                                            >
                                                All Reply
                                            </Button>
                                        </>
                                    }



                                </div>
                            </Row>
                        </CardHeader>

                        <Row className="d-flex" style={{ justifyContent: "right" }}>
                            <div >
                                <Form onSubmit={handleSubmit(onSubmit)} >

                                    <Row className="mr-0">

                                        <Col md="6" >

                                            <FormGroup className="mb-3">
                                                <InputGroup className="input-group-alternative">
                                                    <input
                                                        placeholder="Searching......."
                                                        type="text"
                                                        autoComplete="new-email"
                                                        {...register("companyName")}
                                                        style={{ border: "1px solid" }}
                                                        className="form-control"
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>

                                        <Button color="success" type="submit" className="d-flex" >
                                            Search
                                        </Button>
                                        <Button color="danger" className="d-flex" onClick={clearSearch}
                                            style={{ marginRight: "40px" }}
                                        >
                                            Clear
                                        </Button>

                                    </Row>

                                </Form>

                            </div>
                        </Row>
                        {/* <Row className="mr-5 mb-3 justify-content-end">{
                            checkBoxData?.length > 0 &&
                            <>

                                <Button
                                    color="primary"
                                    onClick={() => setShowModel(true)}
                                >
                                    All Reply
                                </Button>
                            </>
                        }</Row> */}

                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>
                                        <input className="custom-control-input" style={{ opacity: 1, position: "revert" }}
                                            checked={checkedValue}
                                            value={checkedValue} type="checkbox" onClick={(e) => allCheck(!checkedValue)} />
                                    </th>
                                    <th scope="col" width="20%">Name</th>
                                    <th scope="col" width="20%">Phone Number</th>
                                    <th scope="col" width="20%">Email</th>
                                    {/* <th scope="col" width="20%">Subject</th> */}
                                    <th scope="col" width="20%">Message</th>
                                    <th scope="col" width="20%">Entry Date</th>

                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    contactUs?.length > 0 ?
                                        contactUs.map((item, index) => {
                                            return (
                                                <tr key={item?._id}>
                                                    <td>
                                                        <input className="custom-control-input" style={{ opacity: 1, position: "revert" }} id={item?.email} type="checkbox" value={item.email} checked={checkBoxData?.includes(item.email)} onChange={() => { checkBoxHandler(item.email) }} />
                                                    </td>
                                                    <td >{item?.name}</td>
                                                    <td >{item?.phoneNumber}</td>
                                                    <td >{item?.email}</td>
                                                    {/* <th >{item?.subject}</th> */}
                                                    <td onClick={() => Navigate(`/admin/contact-us/view?${item._id}`)} style={{ cursor: "pointer" }}>   {item?.message?.length > 100 ? `${item.message.slice(0, 100)}...` : item?.message}</td>
                                                    <td >{moment(item.createdAt).format("MMMM DD, YYYY hh:mm A ")}</td>
                                                    <td>

                                                        <i style={{ color: "black", width: "30px", fontSize: "20px" }}
                                                            onClick={() => { setShowModel(true); checkBoxHandler(item.email); setSubjectValue(item?.subject) }} class="fa fa-reply" aria-hidden="true"></i>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : "No Record Found"
                                }
                            </tbody>
                        </Table>
                        {contactUs?.length ?
                            <div className="pagination-centered row" >
                                <div className="col-10">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={pageData?.page}
                                        itemsCountPerPage={pageData?.perPage}
                                        totalItemsCount={count}
                                        pageRangeDisplayed={5}
                                        onChange={changePage}
                                    />
                                </div>
                                <div className="col-1 set-drop" >
                                    <select
                                        className="form-control"
                                        value={pageData?.perPage}
                                        onChange={(event) => setPageData((prev) => ({ ...prev, perPage: event.target.value }))}
                                    >
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="75">75</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div> : ''}
                    </Card>
                </Col>
            </Row>
            <Modal
                show={showModel}
                onHide={() => { setShowModel(false); setEmail(""); singleOneResetEmail() }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Message Reply
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmitContactUs(onSubmitContactUS)}>
                    <Modal.Body>
                        <Col md="12">
                            <FormGroup className="mb-3">

                                <label className="d-flex">Subject  </label>
                                <InputGroup className="input-group-alternative">
                                    <input
                                        placeholder="Please Enter Subject"
                                        type="text"
                                        {...registerContactUs("subject", { required: true })}
                                        className="form-control border"
                                    />

                                </InputGroup>
                                {ErrorsContactUs.subject && <span className="text-danger d-flex"> This field is required</span>}
                            </FormGroup>
                            <FormGroup className="mb-3">

                                <label className="d-flex">Message  </label>
                                <InputGroup className="input-group-alternative">
                                    <textarea
                                        placeholder="Please Enter Message"
                                        type="text"
                                        {...registerContactUs("message", { required: true })}
                                        className="form-control border textarea-height"
                                        style={{ height: "200px" }}
                                    />

                                </InputGroup>
                                {ErrorsContactUs.message && <span className="text-danger d-flex"> This field is required</span>}
                            </FormGroup>
                        </Col>
                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={() => { setShowModel(false); setEmail(""); singleOneResetEmail() }}>Close</Button>
                        <Button type="submit">Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default Index


