
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
  } from "reactstrap";
  import { useForm } from "react-hook-form";
  import { baseURL } from '../config/config';
  import { post } from "../utils/apiManager";
  import {  toast } from 'react-toastify';
import { useEffect,useState } from "react";
import { useParams,useNavigate } from 'react-router-dom';

  
  
  const OTP = () => {
  
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { id,password } = useParams();
    const Navigate = useNavigate()

    const [otp, setOtp] = useState(['', '', '', '']);
    const otpInputRefs = [];
  
    // Function to handle OTP input change


    useEffect(()=>{
        if(!id){
        Navigate("/auth/forgot-password")
        }

    },[id])

    const handleOtpChange = (index, value) => {
      if (isNaN(value)) {
        return;
      }
  
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
  
      // Move to the next input field automatically
      if (value !== '' && index < otp.length - 1) {
        otpInputRefs[index + 1].focus();
      }
    };
  
    // Function to handle OTP input keydown
    const handleOtpKeyDown = (index, e) => {
      if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
        otpInputRefs[index - 1].focus();
      }
    };
  
    const onSubmit = (data) => {
      
      const Url = `${baseURL}/admin/otp-verified`

      console.log(otp[0] == "" && otp[1] == "" && otp[2] == "" && otp[3] == "")

      if(otp[0] == "" && otp[1] == "" && otp[2] == "" && otp[3] == ""){
          toast.error("please fill otp")
          return
      }

       let  newOtp = otp.join("") 
       let newData = {
          otp:newOtp,
          password,
          validateString:id
       }
      post(Url, newData).then((data) => handleLogin(data));
      const handleLogin = (data) => {
        if (data?.status) {
          toast.success(data?.message);  
          Navigate("/auth/login")
             
        }
        else {
          toast.error(data?.message)
        }
      };
    }
  
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
           
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Forgot OTP </small>
              </div>
              <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              {otp.map((digit, index) => (
                    <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleOtpKeyDown(index, e)}
                    ref={(ref) => otpInputRefs[index] = ref}
                    style={{ width: '50px',border:"1px solid black",marginLeft:"10px",textAlign:"center" }}
                    
                    />
                ))}
            
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit"
                   onClick={onSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
         
        </Col>
      </>
    );
  };
  
  export default OTP;
  