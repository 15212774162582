import React, { useEffect, useState } from "react";


// reactstrap components
import {
  FormGroup,
  Form,
  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post, get } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";

const Edit = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const Navigate = useNavigate()
  const location = useLocation()

  let categoryId = location?.search?.split("?")[1]?.split("&")[0]
  let id = location?.search?.split("?")[1]?.split("&")[1]
  let categoryName = location?.search?.split("?")[1]?.split("&")[2]?.replaceAll("%20", " ")

  useEffect(() => {
    if (location?.search) {
      getOneCategory(id)
    }
  }, [])


  const getOneCategory = (id) => {
    get(`/sub-category/get-one/${id}`).then((res) => {
      if (res?.datas) {
        setValue("name", res?.datas?.name)
        setValue("description", res?.datas?.description)
      }

    }).catch((error) => {
      console.log(error, "erre");
    })
  }


  const onSubmit = (data) => {
    const Url = `${baseURL}/sub-category/edit`
    data.id = id
    post(Url, data).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
        Navigate(`/admin/category/sub-categories?${categoryId}`)
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }





  return (
    <>

      <Row className="mt-7" >
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>{categoryName} - Sub Category Edit</h2>

                <Button className='bi-trash justify-content-right' style={{
                  position
                    : "absolute", right: 0, marginRight: "40px"
                }} onClick={() => Navigate(`/admin/category/sub-categories?${categoryId}`)} color="primary">Back</Button>
              </div>
              <Form className="" style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                <Row>



                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Name <span className="text-danger">*</span></label>

                      <InputGroup className="input-group-alternative">


                        <input
                          placeholder="Name"
                          type="text"
                          {...register("name", { required: true })}
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.name && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>



                  <Col md="6">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Description  </label>

                      <InputGroup className="input-group-alternative">

                        <textarea
                          placeholder="Enter Description"
                          type="text"
                          {...register("description", { required: false })}
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.description && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>




                </Row>

                <Button color="primary" type="submit" className="d-flex" style={{ marginTop: "10px" }} >
                  Update
                </Button>

              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </>
  );

}

export default Edit;