import React, { useEffect, useState } from "react";


// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Button,
  Card,
  CardHeader
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { post, get } from "../../utils/apiManager";
import { toast } from "react-toastify";
import { baseURL } from "../../config/config";
import CKEditor from 'ckeditor4-react';
import Editor from "../../components/Editor";

const Edit = () => {
  const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
  const Navigate = useNavigate()
  const location = useLocation()
  const [editorData, setEditorData] = useState()



  useEffect(() => {
    if (location?.search) {
      getOneEmailTemplate(location?.search?.split("?")[1])
    }
  }, [])


  const getOneEmailTemplate = (id) => {
    get(`/company/email/${id}`).then((res) => {
      if (res?.datas) {
        setValue("title", res?.datas?.title)
        setValue("subject", res?.datas?.subject)
        setValue("description", res?.datas?.description)
        setValue("slug", res?.datas?.slug)
        setValue("isCompany", res?.datas?.isCompany)
        setValue("isModified", res?.datas?.isModified)
        setValue("isSelf", res?.datas?.isSelf)
        setEditorData(res?.datas?.description)
      }

    }).catch((error) => {
      console.log(error, "erre");
    })
  }

  const onSubmit = (data) => {
 
    const Url = `${baseURL}/company/email-update`
    let id = location?.search?.split("?")[1]
    let newData = { ...data, id, description: editorData }
    post(Url, newData).then((data) => { 
      if (data?.status) {
        toast.success(data?.message);
        Navigate(-1) 

      }
      else {

        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })

  }


  return (
    <>
      {/* <Header /> */}
      {/* <Link to="/admin/user/index">
            <Button color="primary" type="button" className="mt-3" style={{position:"absolute",left:0}}>
           Back
        </Button>
        </Link> */}
      <Row className="mt-7" >
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <div className="d-flex">
                <h2 style={{ textAlign: "left", marginTop: "10px" }}>Email Template Edit</h2>

                <Button className='bi-trash justify-content-right' style={{
                  position 
                    : "absolute", right: 0, marginRight: "40px" 
                }} onClick={() => Navigate(-1)} color="primary">Back</Button>
              </div>
              <Form className="" style={{ marginTop: "40px" }} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Title <span className="text-danger">*</span></label>

                      <InputGroup className="input-group-alternative">
                        {/* <InputGroupAddon addonType="prepend"> */}
                        {/* </InputGroupAddon> */}

                        <input
                          placeholder="Title"
                          type="text"
                          autoComplete="new-email"
                          {...register("title", { required: true })}
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.company_name && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Slug <span className="text-danger">*</span></label>

                      <InputGroup className="input-group-alternative">
                        {/* <InputGroupAddon addonType="prepend"> */}
                        {/* </InputGroupAddon> */}
                        <input
                          placeholder="slug"
                          type="text"
                          autoComplete="new-email"
                          {...register("slug", { required: true })}
                          disabled
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.slug && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="mb-3">
                      <label className="d-flex">Subject <span className="text-danger">*</span></label>

                      <InputGroup className="input-group-alternative">
                        {/* <InputGroupAddon addonType="prepend"> */}
                        {/* </InputGroupAddon> */}
                        <input
                          placeholder="Subject"
                          type="text"
                          {...register("subject", { required: true })}
                          className="form-control border"
                        />

                      </InputGroup>
                      {errors.subject && <span className="text-danger d-flex"> This field is required</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Editor editorData={editorData} setEditorData={setEditorData} />
                  </Col>
                  
                </Row>

                <Button color="primary" type="submit" className="d-flex" style={{ marginTop: "10px" }} >
                  Update
                </Button>

              </Form>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </>
  );

}

export default Edit;