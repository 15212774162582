import React, { useEffect, useState } from "react";
// reactstrap components
import {

  Table,

  Button,
  Row,
  Col,
  Card,
  CardHeader,
  InputGroup,
  FormGroup,
  Form
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { Link, useNavigate } from 'react-router-dom';
import { get, post } from "../../utils/apiManager";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
// import PaginatedItems from "../../components/ReactPagination";
import Pagination from 'react-js-pagination'
import { useForm } from "react-hook-form";


const Index = () => {
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();

  const [blog, setBlog] = useState([])
  const Navigate = useNavigate()
  const [count, setCount] = useState()
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 25,
    searchItem: "",
  });

  useEffect(() => {
    getAllBlog()
  }, [pageData])

  const getAllBlog = () => {
    get(`/blog?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${getValues("companyName")}`).then((res) => {
        setBlog(res?.data)
        setCount(res?.pages)
      }).catch((error) => {
        console.log(error, "erre");
      })
  }

  const changeStatus = (id, status) => {
    const Url = `${baseURL}/blog/status`
    let data = { id, status: !status }
    post(Url, data).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
        getAllBlog()
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)
    })
  }

  const deleteHandler = (id) => {
    const Url = `${baseURL}/blog/delete`
    let data = { id }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t delete this article',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            toast.success(data?.message);
            getAllBlog()
          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    });
  };

  const onSubmit = (data) => {
    getAllBlog()
  }

  const clearSearch = () => {
    setValue("companyName", "")
    getAllBlog()
  }

  return (
    <>
      <Row className="mt-7" >
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center " >
                <div className="col">
                  <h3 className="mb-0" style={{ textAlign: "left" }}>Article Content</h3>
                </div>
                <Link to="/admin/blog/add">
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                    >
                      Add Article
                    </Button>
                  </div>
                </Link>
              </Row>
            </CardHeader>

            <Row className="d-flex" style={{ justifyContent: "right" }}>
              <div >
                <Form onSubmit={handleSubmit(onSubmit)} >
                  <Row>
                    <Col md="6" >
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <input
                            placeholder="Searching......."
                            type="text"
                            autoComplete="new-email"
                            {...register("companyName")}
                            style={{ border: "1px solid" }}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Button color="success" type="submit" className="d-flex" >
                      Search
                    </Button>
                    <Button color="danger" className="d-flex" onClick={clearSearch}
                      style={{ marginRight: "40px" }}
                    >
                      Clear
                    </Button>
                  </Row>
                </Form>
              </div>
            </Row>

            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Image</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  blog?.length > 0 ?
                    blog.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <th >{item?.title}</th>
                          <td>{item?.image ? <img src={baseURL + "/" + item?.image} width="100px" height="100px" /> : "-"}</td>
                          <td>{item?.isActive ? <Button color="success" type="submit" className="">
                            Active
                          </Button> : <Button color="danger" type="submit" className="">
                            Deactive
                          </Button>}</td>

                          <td>
                            {
                              item?.isActive ?
                                <i style={{ color: "red", width: "30px", fontSize: "20px" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-times" aria-hidden="true"></i> :
                                <i style={{ color: "green", width: "30px", fontSize: "20px" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-check" aria-hidden="true"></i>
                            }
                            <i style={{ color: "black", width: "30px", fontSize: "20px" }} onClick={() => Navigate(`/admin/blog/edit?${item._id}`)} class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            <i onClick={() => deleteHandler(item._id)} class="fa fa-trash" aria-hidden="true" style={{ color: "red", width: "30px", fontSize: "20px" }}></i>
                            <i style={{ color: "black", width: "30px", fontSize: "20px" }} onClick={() => Navigate(`/admin/blog/view?${item._id}`)} class="fa fa-eye" aria-hidden="true"></i>
                          </td>
                        </tr>
                      )
                    })
                    : "No Record Found"
                }
              </tbody>
            </Table>
            {blog?.length ?
              <div className="pagination-centered">
                <div className="col-md-10">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={count}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  />

                </div>
                <div className="col-1 set-drop">
                  <select
                    id="role"
                    name="role"
                    className="form-control"
                    onChange={(event) => setPageData((prev) => ({ ...prev, perPage: event.target.value }))}
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                  </select>
                </div>

              </div> : ''}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Index


