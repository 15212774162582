import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Dropdown,
  Progress,
  Table,
  UncontrolledTooltip,
  Container,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  InputGroupText,
  InputGroup,
  FormGroup,
  Form
} from "reactstrap";
import { Link, useNavigate } from 'react-router-dom';
import { get, post } from "../../utils/apiManager";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import Pagination from 'react-js-pagination'
import { useForm } from "react-hook-form";
import { store } from "../../reduxToolkit/store/store";


const Index = () => {
  const { register, handleSubmit, watch, formState: { errors }, getValues, setValue } = useForm();

  const [companies, setCompanies] = useState([])
  const Navigate = useNavigate()
  const [count, setCount] = useState()
  const [statusValue,setStatusValue] = useState("")
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 25, 
    searchItem: "",
  });
  const [showDropdown,setShowDropdown] = useState(false)
  const [indexValue,setIndexValue] = useState()


  useEffect(() => {
    getAllCompanies()
  }, [pageData, statusValue])

  const getAllCompanies = () => {
    get(`/company?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${getValues("companyName")}&statusValue=${statusValue}`).then((res) => {
        setCompanies(res?.data)
        setCount(res?.pages)
      }).catch((error) => {
        console.log(error, "erre");
      })
  }

  const changeStatus = (id, status) => {
    const Url = `${baseURL}/company/status`
    let data = { id, status: !status }
    post(Url, data).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
        getAllCompanies()
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)
    })
  }

  const deleteHandler = (id) => {
    const Url = `${baseURL}/company/delete`
    let data = { id }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t delete this company',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            toast.success(data?.message);
            getAllCompanies()
          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    });
  };

  const onSubmit = (data) => {
    getAllCompanies()
  }

  const clearSearch = () => {
    setValue("companyName", "")
    getAllCompanies()
  }

  const sendCredentials = (id) => {

    const Url = `${baseURL}/company/send-credentials`
   
    post(Url,  {id} ).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)
    })
  }

  const verifiedHandler = (id) => {
    const Url = `${baseURL}/company/verified`
    let data = { id }
    post(Url, data).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
        getAllCompanies()
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)
    })
  }

  // const [dropdownOpen, setDropdownOpen] = useState(false);

  // const toggle = () => setDropdownOpen((prevState) => !prevState);
 

  return (
    <>
       

      <Row className="mt-7" >
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center " >
                <div className="col">
                  <h3 className="mb-0" style={{ textAlign: "left" }}>All Companies</h3>
                </div>
                <Link to="/admin/user/add">
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                    >
                      Add Companies
                    </Button>
                  </div>
                </Link>
              </Row>
            </CardHeader>
      

            {/* <div className="d-flex p-5">
              <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                <DropdownToggle caret>Dropdown</DropdownToggle>
                <DropdownMenu >
                  <DropdownItem><i className="fa fa-times"></i>Some Action</DropdownItem>
                  <DropdownItem><i className="fa fa-times"></i>Some Action</DropdownItem>
                  <DropdownItem><i className="fa fa-times"></i>Some Action</DropdownItem>
                  <DropdownItem><i className="fa fa-times"></i>Some Action</DropdownItem>
                  <DropdownItem><i className="fa fa-times"></i>Some Action</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div> */}

            <Row className="d-flex" style={{ justifyContent: "right" }}>
              <div >
                <Form onSubmit={handleSubmit(onSubmit)} >
                  <Row>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <input
                            placeholder="Searching......."
                            type="text"
                            autoComplete="new-email"
                            {...register("companyName")}
                            style={{ border: "1px solid" }}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>

                    {/* <Col md="3"> */}
                      <FormGroup >
                        <InputGroup className="input-group-alternative ">
                          <select
                            className="form-control border ml-3 mr-3"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            onChange={(e) => setStatusValue(e.target.value)}
                           

                          // onChange={(e) => stateChangeHandler(e.target.value)}
                          >
                            {/* <option selected="" value="">Select</option> */}
                          <option selected="" value="">All</option>
                            <option  value={true}>Paid</option>
                            <option  value={false}>Unpaid</option>
                           

                          </select>
                        </InputGroup>

                        {errors.state && <span className="text-danger d-flex">This field is required</span>}
                        {/* <input
                          placeholder="State"
                          type="text"
                          autoComplete="new-email"
                          {...register("state", { required: true })}
                          className="border form-control"
                        />
                      </InputGroup>
                      {errors.state && <span className="text-danger d-flex"> This field is required</span>} */}
                      </FormGroup>
                      <Button color="success" type="submit" className="d-flex" >
                        Search
                      </Button>
                      <Button color="danger" className="d-flex" onClick={clearSearch}
                        style={{ marginRight: "40px" }}
                      >
                        Clear
                      </Button>
                    {/* </Col> */}

                  
                  </Row>
                </Form>
              </div>
            </Row>

            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Company name</th>
                  <th scope="col">Company type</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Apt Suite</th>
                  <th scope="col">Verified</th>
                  <th scope="col">Status</th>
                  <th scope="col">Paid / Unpaid</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  companies?.length > 0 ?
                    companies.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <th scope="row">{item?.firstName ? item?.firstName   + " " +   item?.lastName :   "-"}</th>
                          <td>{item?.companyName || "-"}</td>
                          <td>{item?.companyType || "-"} </td>
                          <td>{item?.email || "-"}</td>
                          <td>{item?.phone || "-"}</td>
                          <td>{item?.addressAptSuite || "-"}</td>
                         
                          <td>{item?.isVerified ? <Button color="success" type="submit" className="">
                            Verified
                          </Button> : <Button color="danger" type="submit" className="">
                            Notverified
                          </Button>}</td>

                          <td>{item?.isActive ? <Button color="success" type="submit" className="">
                            Active
                          </Button> : <Button color="danger" type="submit" className="">
                            Deactive
                          </Button>}</td>

                          <td>{item?.isPaid ? <Button color="success" type="submit" className="">
                            Paid
                          </Button> : <Button color="warning" type="submit" className="">
                            Unpaid
                          </Button>}</td>

                          <td>
                            <div class="dropdown " onClick={() => {setShowDropdown(!showDropdown);setIndexValue(index)}}>
                              <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="true">
                                <i className="fa fa-ellipsis-v"></i>
                              </button>
                              <ul className={`dropdown-menu ${showDropdown && indexValue == index ? "show" : ""}`}>
                                {!item?.isVerified && 
                                <li>
                                    <a className="dropdown-item" onClick={() => verifiedHandler(item._id)}><i style={{ color: "green", width: "30px", fontSize: "20px" }}  class="fa fa-check" aria-hidden="true"></i> Verified</a>
                                </li>
                    }
                                <li>
                                  <a className="dropdown-item" onClick={() => changeStatus(item._id, item?.isActive)}>{
                                    item?.isActive ?
                                      <i style={{ color: "red", width: "30px", fontSize: "20px" }}  class="fa fa-times" aria-hidden="true"></i> :
                                      <i style={{ color: "green", width: "30px", fontSize: "20px" }}  class="fa fa-check" aria-hidden="true"></i>
                                  } Status </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" onClick={() => Navigate(`/admin/user/edit?${item._id}`)} > <i style={{ color: "black", width: "30px", fontSize: "20px" }}  class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                                   </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" onClick={() => deleteHandler(item._id)}>
                                  <i  class="fa fa-trash" aria-hidden="true" style={{ color: "red", width: "30px", fontSize: "20px" }}></i> Delete
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" onClick={() => Navigate(`/admin/user/view?${item._id}`)}>  <i style={{ color: "black", width: "30px", fontSize: "20px" }}  class="fa fa-eye" aria-hidden="true"></i> View</a>
                                </li>
                                <li>
                                  <a className="dropdown-item" onClick={() => sendCredentials(item._id)}>    <i style={{ color: "black", width: "30px", fontSize: "20px" }}  class="fa fa-send" aria-hidden="true"></i> Send Credential</a>
                                </li>
                               
                                <li>
                                  <a className="dropdown-item" onClick={() => Navigate(`/admin/change-password?${item._id}`)}>     <i style={{ color: "black", width: "30px", fontSize: "20px" }}  class="fa fa-key" aria-hidden="true"></i> Change Password
                                  </a>
                                </li>

                                <li>
                                  <a className="dropdown-item" onClick={() => Navigate(`/admin/email-template?${item._id}`)}>     <i style={{ color: "black", width: "30px", fontSize: "20px" }}  class="fa fa-eye" aria-hidden="true"></i> View email template
                                  </a>
                                </li>

                             
                              </ul>
                            </div>
                            

                            
                            
                           
                           
                          
                           

                          </td>
                        </tr>
                      )
                    })
                    : "No Record Found"
                }
              </tbody>
            </Table>
            {companies?.length ? 
              <div className="pagination-centered row">
                <div className="col-10">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={pageData?.page}
                itemsCountPerPage={pageData?.perPage}
                totalItemsCount={count}
                pageRangeDisplayed={5}
                onChange={changePage}
              />
              </div>
                <div className="col-1 set-drop" >

                <select
                  id="role"
                  name="role"
                  className="form-control"
                  onChange={(event) => setPageData((prev) => ({ ...prev, perPage: event.target.value }))}
                >
                   <option value="25">25</option>
                   <option value="50">50</option>
                   <option value="75">75</option>
                   <option value="100">100</option>
                </select>
                </div>
                </div> : ''}
           

          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Index




// import React, { useEffect,useState } from "react";
// // reactstrap components
// import {
//   Badge,
//   DropdownMenu,
//   DropdownItem,
//   UncontrolledDropdown,
//   DropdownToggle,
//   Media,
//   Progress,
//   Table,
//   UncontrolledTooltip,
//   Container,
//   Button,
//   Row,
//   Col,
//   Card,
//   CardHeader,
//   InputGroupText,
//   InputGroup,
//   FormGroup,
//   Form
// } from "reactstrap";
// import Header from "../../components/Headers/Header";
// import {Link, useNavigate} from 'react-router-dom';
// import { get, post } from "../../utils/apiManager";
// import { baseURL } from "../../config/config";
// import { toast } from "react-toastify";
// import Swal from 'sweetalert2'
// // import PaginatedItems from "../../components/ReactPagination";
// import Pagination from 'react-js-pagination'
// import { useForm } from "react-hook-form";


// const Index = () =>  {
//   const { register, handleSubmit, watch, formState: { errors } ,getValues,setValue} = useForm();

//     const [companies,setCompanies] = useState([])
//     const Navigate = useNavigate()
//     const [count, setCount] = useState()
//     const [pageData, setPageData] = useState({
//         page: 1,
//         perPage: 10,
//         searchItem: "",
//     });

//     useEffect(()=>{
//             getAllCompanies()
//     },[pageData])


//     const getAllCompanies = () => {
//         get(`/company?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
//         }&searchItem=${getValues("companyName")}`).then((res) => {

//             setCompanies(res?.data)
//             // console.log(res)
//             setCount(res?.pages)
//           }).catch((error) => {
//             console.log(error, "erre");
//           })
//     }

//     const changeStatus = (id,status) => {
//         const Url = `${baseURL}/company/status`
//         let data  = {id,status:!status}
//         post(Url, data).then((data) => {  
//             if (data?.status) {
//             toast.success(data?.message);
//             getAllCompanies()
            
//           }
//           else {
      
//             toast.error(data?.message)
//           }}).catch(err=>{
//             toast.error(err.response.data.error)
      
//           }) 
//     }

//     const deleteHandler = (id) =>{
//         const Url = `${baseURL}/company/delete`
//         let data  = {id}
//           Swal.fire({
//             title: 'Are you sure?',
//             text: 'You won\'t delete this company',
//             icon: 'warning',
//             showCancelButton: true,
//             confirmButtonColor: '#3085d6',
//             cancelButtonColor: '#d33',
//             confirmButtonText: 'Yes, delete it!'
//           }).then((result) => {
//             if (result.isConfirmed) {
//               // Perform the delete action
//                 post(Url, data).then((data) => {  
//                   if (data?.status) {
//                   toast.success(data?.message);
//                   getAllCompanies()
                  
//                 }
//                 else {
            
//                   toast.error(data?.message)
//                 }}).catch(err=>{
//                   toast.error(err.response.data.error)
            
//                 }) 
            
//             }
//           });
     
       
//     }

//     const changePage = (index) => {
//       setPageData({
//           ...pageData,
//           page: index,
//       });
//   };

//   const onSubmit = (data) => {
//     getAllCompanies()
//   }

// const clearSearch = () => {
//   setValue("companyName","")
//   getAllCompanies()
// }


//     return (
//       <>
           
//          <Row className="mt-7" >
//           <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
//             <Card className="shadow">
//               <CardHeader className="border-0">
//                 <Row className="align-items-center " >
//                   <div className="col">
//                     <h3 className="mb-0" style={{textAlign:"left"}}>All Companies</h3>
//                   </div>
//                   <Link to="/admin/user/add">
//                   <div className="col text-right">
//                     <Button
//                       color="primary"
//                       href="#pablo"
//                     //   onClick={(e) => e.preventDefault()}
//                     >
//                       Add Companies
//                     </Button>
//                   </div>
//                   </Link>
//                 </Row>
//               </CardHeader>
          
//         <Row className="d-flex" style={{justifyContent:"right"}}>
//         <div >
//                 <Form onSubmit={handleSubmit(onSubmit)} >
//                 <Row>
//             <Col md="6" >
//             <FormGroup className="mb-3">
//                 <InputGroup className="input-group-alternative">
//                   {/* <InputGroupAddon addonType="prepend"> */}
               
//                   {/* </InputGroupAddon> */}
//                   <input
//                     placeholder="Searching......."
//                     type="text"
//                     autoComplete="new-email"
//                     {...register("companyName")}
//                     style={{border:"1px solid"}}
//                     className="form-control"
//                   />

//                 </InputGroup>
//               </FormGroup>
//               </Col>
   
//         <Button color="success" type="submit" className="d-flex" >
//            Search
//         </Button>
//         <Button color="danger"  className="d-flex" onClick={clearSearch} 
//          style={{marginRight:"40px"}}
//         >
//            Clear
//         </Button>
      
        
//         </Row>
//         </Form>
//         </div>
           
//           </Row>
//               <Table className="align-items-center table-flush" responsive>
//                 <thead className="thead-light">
//                   <tr>
//                     <th scope="col">Name</th>
//                     <th scope="col">Company name</th>
//                     <th scope="col">Company type</th>
//                     <th scope="col">Email</th>
//                     <th scope="col">Phone</th>
//                     <th scope="col">Address Apt Suite</th>
//                     <th scope="col">Status</th>
//                     <th scope="col">Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                     {
//                         companies.length > 0 ?   
//                         companies.map((item,index)=>{
//                             return (
//                                 <tr key={item?._id}>
//                                 <th scope="row">{item?.firstName + " " + item?.lastName}</th>
//                                 <td>{item?.companyName}</td>
//                                 <td>{item?.companyType}</td>
//                                 <td>{item?.email}</td>
//                                 <td>{item?.phone}</td>
//                                 <td>{item?.addressAptSuite}</td>
//                                 <td>{item?.isActive ? <Button color="success" type="submit" className="">
//                                           Active
//                              </Button> : <Button color="danger" type="submit" className="">
//                                           Deactive
//                              </Button>}</td>
                               
//                                 <td>
                              
//                                 {
                                  
//                                         item?.isActive ?
//                                         <i style={{color:"red",width:"30px",fontSize:"20px"}} onClick={() => changeStatus(item._id,item?.isActive)} class="fa fa-times" aria-hidden="true"></i>:
//                                         <i  style={{color:"green",width:"30px",fontSize:"20px"}} onClick={() => changeStatus(item._id,item?.isActive)} class="fa fa-check" aria-hidden="true"></i>
 

//                                 }
                              

//                             <i style={{color:"black",width:"30px",fontSize:"20px"}} onClick={() => Navigate(`/admin/user/edit?${item._id}`)} class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                  
//                                         <i onClick={() => deleteHandler(item._id)} class="fa fa-trash" aria-hidden="true" style={{color:"red",width:"30px",fontSize:"20px"}}></i>
                                  
//                                         <i  style={{color:"black",width:"30px",fontSize:"20px"}} onClick={() => Navigate(`/admin/user/view?${item._id}`)} class="fa fa-eye" aria-hidden="true"></i>

//                                 </td>
    
//                               </tr> 
//                             )
                          
//                         })
                        
//                    : "No Record Found"
//                     }

                               
                 
                
//                 </tbody>
//                                {companies?.length ? <Pagination
//                                 style={{justifyContent:"right"}}
//                                         itemClass="page-item"
//                                         linkClass="page-link"
//                                         activePage={pageData?.page}
//                                         itemsCountPerPage={pageData?.perPage}
//                                         totalItemsCount={count}
//                                         pageRangeDisplayed={5}
//                                         onChange={changePage}
//                                     /> : ''}
//               </Table>
            
//             </Card>
//           </Col>
          
         
//         </Row>
//       </>
//     );
  
// }

// export default Index