import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  InputGroup,
  FormGroup,
  Form
} from "reactstrap";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { get, post } from "../../utils/apiManager";
import { baseURL } from "../../config/config";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
import Pagination from 'react-js-pagination'
import { useForm } from "react-hook-form";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Modal from 'react-bootstrap/Modal';

const Index = () => {
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
  const { register: registerExcel, handleSubmit: handleSubmitExcel, formState: { errors: ErrorsExcel }, getValues: getValuesExcel, setValue: setValueExcel } = useForm();
  const [subCategory, setSubCategory] = useState([])
  const [importModel, setImportModel] = useState(false)
  const Navigate = useNavigate()
  const [count, setCount] = useState()
  const [allData, setAllData] = useState([])
  const [pageData, setPageData] = useState({
    page: 1,  
    perPage: 50,
    searchItem: "",
  });
  const [checkBoxData, setCheckBoxData] = useState([])
  const location = useLocation()
  let id = location?.search?.split("?")[1]

  useEffect(() => {
    getAllSubCategory()
  }, [pageData])

  const getAllSubCategory = () => {
    get(`/sub-category/${id}?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${getValues("companyName")}`).then((res) => {
        setSubCategory(res?.data)
        setCount(res?.pages)
        setAllData(res?.allSubCategoryData)
      }).catch((error) => {
        console.log(error, "erre");
      })
  }

  const changeStatus = (id, status) => {
    const Url = `${baseURL}/sub-category/status`
    let data = { id, status: !status }
    post(Url, data).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
        getAllSubCategory()
      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)
    })
  }

  const deleteHandler = (id) => {
    const Url = `${baseURL}/sub-category/delete`
    let data = { id }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t delete this sub-category',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            toast.success(data?.message);
            getAllSubCategory()

          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    });
  };

  const onSubmit = (data) => {
    getAllSubCategory()
  }

  const clearSearch = () => {
    setValue("companyName", "")
    getAllSubCategory()
  }

  const handleExport = () => {
    let exportsData = []
    allData?.map((item) => {

      exportsData.push({ "categoryName": item?.categoryName, "subCategoryName": item?.subCategoryName, description: item.description, status: item?.status ? "Active" : "Deactive" })
    })

    const ws = XLSX.utils.json_to_sheet(exportsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    saveAs(blob, 'subcategory.xlsx');
  };

  const onSubmitExcelFile = (data) => {

    const Url = `${baseURL}/sub-category/excel/add`
    let formData = new FormData();
    formData.append("excel", data?.excelFile?.[0])

    formData.append("categoryId", id)
    post(Url, formData, 1).then((data) => {
      if (data?.status) {
        toast.success(data?.message);
        setImportModel(false)
        getAllSubCategory()

      }
      else {
        toast.error(data?.message)
      }
    }).catch(err => {
      toast.error(err.response.data.error)

    })
  }


  const activeStatusHandler = () => {
    const Url = `${baseURL}/sub-category/multi-status`
    let data = { ids: checkBoxData, status: true }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t active this sub categories',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            toast.success(data?.message);
            getAllSubCategory()
            setCheckBoxData([])
          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }

  const deActiveStatusHandler = () => {
    const Url = `${baseURL}/sub-category/multi-status`
    let data = { ids: checkBoxData, status: false }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t deactive this sub categories',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            toast.success(data?.message);
            getAllSubCategory()
            setCheckBoxData([])

          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }

  const allDeleteHandler = () => {
    const Url = `${baseURL}/sub-category/multi-delete`
    let data = { ids: checkBoxData }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t deactive this categories',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        post(Url, data).then((data) => {
          if (data?.status) {
            toast.success(data?.message);
            getAllSubCategory()
            setCheckBoxData([])

          }
          else {
            toast.error(data?.message)
          }
        }).catch(err => {
          toast.error(err.response.data.error)
        })
      }
    });
  }

  const checkBoxHandler = (id) => {
    let findData = checkBoxData?.find(item => item == id)
    if (findData) {
      let filterData = checkBoxData?.filter((item) => item != id)
      setCheckBoxData(filterData)
    } else {
      setCheckBoxData((prev) => [...prev, id])
    }
  }


  return (
    <>
      <Row className="mt-7" >
        <Col className="mb-5 mb-xl-0 ml-5" xl="11" >
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center " >
                <div className="col">
                  <h3 className="mb-0" style={{ textAlign: "left" }}>{allData?.[0]?.categoryName} - Sub Categories</h3>
                </div>
                {
                  checkBoxData?.length > 0 &&
                  <>
                    <Button
                      color="primary"
                      onClick={activeStatusHandler}
                    >
                      All Active
                    </Button>
                    <Button
                      color="primary"
                      onClick={deActiveStatusHandler}

                    >
                      All Deactive
                    </Button>
                    <Button
                      color="primary"
                      onClick={allDeleteHandler}
                    >
                      All Delete
                    </Button>
                  </>
                }
                <Link to={`/admin/category`}>
                  <div className="col text-right">
                    <Button
                      color="primary"
                    >
                      Back to Category
                    </Button>
                  </div>
                </Link>
                {/* <Button
                  color="primary"
                  onClick={() => setImportModel(true)}
                >
                  Import
                </Button>
                <Button
                  color="primary"
                  onClick={handleExport}
                >
                  Export
                </Button> */}
                <Link to={`/admin/sub-category/add?${id}&${allData?.[0]?.categoryName}`}>
                  <div className="col text-right">
                    <Button
                      color="primary"
                    >
                      Add Sub Category
                    </Button>
                  </div>
                </Link>
              </Row>
            </CardHeader>

            <Row className="d-flex" style={{ justifyContent: "right" }}>
              <div >
                <Form onSubmit={handleSubmit(onSubmit)} >
                  <Row>
                    <Col md="6" >
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <input
                            placeholder="Searching......."
                            type="text"
                            autoComplete="new-email"
                            {...register("companyName")}
                            style={{ border: "1px solid" }}
                            className="form-control"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>

                    <Button color="success" type="submit" className="d-flex" >
                      Search
                    </Button>
                    <Button color="danger" className="d-flex" onClick={clearSearch}
                      style={{ marginRight: "40px" }}
                    >
                      Clear
                    </Button>
                  </Row>
                </Form>
              </div>
            </Row>

            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Checkbox</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  subCategory?.length > 0 ?
                    subCategory.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>
                            <input class="custom-control-input" style={{ opacity: 1, position: "revert" }} id={item?._id} type="checkbox" value={item._id} checked={checkBoxData?.includes(item._id)} onChange={() => { checkBoxHandler(item._id) }} />
                          </td>
                          <td >{item?.name}</td>
                          <td >{item?.description ? item?.description : "-"}</td>
                          <td>{item?.isActive ? <Button color="success" type="submit" className="">
                            Active
                          </Button> : <Button color="danger" type="submit" className="">
                            Deactive
                          </Button>}</td>

                          <td>
                            {
                              item?.isActive ?
                                <i style={{ color: "red", width: "30px", fontSize: "20px" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-times" aria-hidden="true"></i> :
                                <i style={{ color: "green", width: "30px", fontSize: "20px" }} onClick={() => changeStatus(item._id, item?.isActive)} class="fa fa-check" aria-hidden="true"></i>
                            }
                            <i style={{ color: "black", width: "30px", fontSize: "20px" }} onClick={() => Navigate(`/admin/category/sub-category/edit?${id}&${item._id}&${allData?.[0]?.categoryName}`)} class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            <i onClick={() => deleteHandler(item._id)} class="fa fa-trash" aria-hidden="true" style={{ color: "red", width: "30px", fontSize: "20px" }}></i>
                            {/* <i style={{ color: "black", width: "30px", fontSize: "20px" }} onClick={() => Navigate(`/admin/category/view?${item._id}`)} class="fa fa-eye" aria-hidden="true"></i> */}
                          </td>
                        </tr>
                      )
                    })
                    : "No Record Found"
                }
              </tbody>
            </Table>
            {subCategory?.length ?
              <div className="pagination-centered row">
                <div className="col-10">

                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={pageData?.page}
                    itemsCountPerPage={pageData?.perPage}
                    totalItemsCount={count}
                    pageRangeDisplayed={5}
                    onChange={changePage}
                  />
                </div>
                <div className="col-1 set-drop" >

                  <select
                    id="role"
                    className="form-control"
                    value={pageData?.perPage}
                    onChange={(event) => setPageData((prev) => ({ ...prev, perPage: event.target.value }))}
                  >
                     <option value="25">25</option>
        <option value="50">50</option>
        <option value="75">75</option>
        <option value="100">100</option>
                  </select>
                </div>

              </div>


              : ''}
          </Card>
        </Col>
        <Modal
          show={importModel}
          onHide={() => setImportModel(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload Sub Categories
            </Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmitExcel(onSubmitExcelFile)}>
            <Modal.Body>
              <Col md="12">
                <FormGroup className="mb-3">

                  <label className="d-flex">Upload File  </label>
                  <InputGroup className="input-group-alternative">
                    <input
                      placeholder="Please Select File"
                      type="file"
                      {...registerExcel("excelFile", { required: true })}
                      className="form-control border"
                      accept=".xlsx, .xls"
                    />

                  </InputGroup>
                  {ErrorsExcel.excelFile && <span className="text-danger d-flex"> This field is required</span>}
                </FormGroup>
              </Col>
            </Modal.Body>

            <Modal.Footer>

              <Button onClick={() => setImportModel(false)}>Close</Button>
              <Button type="submit">Submit</Button>
            </Modal.Footer>
          </form>
        </Modal>

      </Row>
    </>
  );
}

export default Index


